// import React from 'react'
// import { shallow } from 'enzyme'

// import StoryblokComponent from '../index'

describe('<StoryblokComponent />', () => {
  it('Expect to have unit tests specified', () => {
    expect(true).toEqual(false)
  })
})
